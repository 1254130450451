import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const DecisionTreeD3 = ({ result, fieldList, setCaminhoAtual }) => {
   const [currentData, setCurrentData] = useState(result);
   const d3Chart = useRef();
   const currentNode = useRef();
   const colorPalette = require("../../assets/data/colorPalette.json");
   const modelColors = colorPalette.models;
   const predictTrueColor = colorPalette.decision.predictTrue;
   const predictFalseColor = colorPalette.decision.predictFalse;

   const { t } = useTranslation();

   // Define a cor dos nós com base no KPI
   function DecisionTreeKPI(node) {
      if (node.data.Feature === undefined) {
         if (node.data.Predict === "1.0" || node.data.Predict === 1) {
            return predictTrueColor;
         } else {
            return predictFalseColor;
         }
      } else if (fieldList !== null && fieldList !== undefined) {
         for (let i = 0; i < fieldList.length; i++) {
            if (i > modelColors.length) {
               return modelColors[modelColors.length - (i % modelColors.length)];
            } else if (fieldList[i] === node.data.Feature) {
               return modelColors[i];
            }
         }
      }
      return "#404040";
   }

   // Função que desenha a árvore de decisão
   function desenharDecisionTree() {
      if (currentData === null || currentData === undefined) return;

      d3.select("#decisiontree-graph").remove();
      d3.select("#decisiontree-links").remove();
      d3.select("#decisiontree-nodes").remove();

      const margin = { top: -55, right: 10, bottom: 90, left: -30 };
      const width = parseInt(d3.select(".model-result-1").style("width"), 10) - margin.left - margin.right;
      const height = parseInt(d3.select(".model-result-1").style("height"), 10) - margin.top - margin.bottom;

      const svg = d3
         .select(d3Chart.current)
         .attr("viewBox", `0 0 ${width} ${height}`)
         .attr("preserveAspectRatio", "xMidYMid meet")
         .append("g")
         .attr("transform", `translate(${margin.left},${margin.top})`);

      let graph = svg.append("g").attr("id", "decisiontree-graph").attr("transform", "translate(50,50)");
      let hierarchy = d3.hierarchy(currentData);
      let tree = d3.tree().size([width - 40, height - 0]);
      let myTree = tree(hierarchy);

      // Seleciona e desenha links
      let links = graph.selectAll(".link").data(myTree.links());
      links
         .enter()
         .append("path")
         .attr("id", "decisiontree-links")
         .transition()
         .duration(0)
         .attr("class", "link")
         .attr("fill", "none")
         .attr("stroke", "#aaa")
         .attr("stroke-width", 2)
         .attr(
            "d",
            d3
               .linkVertical()
               .x((d) => d.x)
               .y((d) => d.y),
         )
         .transition();

      // Seleciona e desenha nós
      let nodes = graph.selectAll(".node").data(myTree.descendants());
      nodes
         .enter()
         .append("g")
         .attr("id", "decisiontree-nodes")
         .on("mouseover", handleMouseOver)
         .on("mouseout", handleMouseOut)
         .on("click", (event) => handleEvent(event))
         .attr("class", "node")
         .attr("transform", (node) => `translate(${node.x},${node.y})`)
         .append("circle")
         .attr("r", 15)
         .attr("fill", (node) => DecisionTreeKPI(node));
   }

   // Tooltip gerenciado pelo D3
   useEffect(() => {
      d3.select("body")
         .append("div")
         .attr("class", "d3-tooltip")
         .style("position", "absolute")
         .style("background-color", "black")
         .style("color", "white")
         .style("padding", "5px")
         .style("border-radius", "5px")
         .style("opacity", 0); // Tooltip inicial escondido

      desenharDecisionTree();

      // Redesenha a árvore de decisão quando currentData ou fieldList mudarem
   }, [currentData, fieldList]);

   function handleMouseOver(event, node) {
      const tooltip = d3.select(".d3-tooltip");
      let feature;
      if (node?.data?.Feature) {
         feature = node.data.Feature;
      } else if (node?.data?.Predict && isNaN(node.data?.Predict)) {
         feature = `Predict: ${node.data.Predict}`;
      } else {
         feature = `Predict: ${node.data.Predict === "1"}`;
      }
      // const feature = node.data?.Feature ? node.data.Feature : `Predict: ${node.data.Predict === "1"}`;
      const dataPoints = node.data.DataPoints;
      const percentage = node.data.Percentage;

      // Display the tooltip with styled HTML content
      tooltip
         .style("opacity", 1)
         .html(
            `
         <div style="padding: 8px; font-size: 14px; color: white;">
            <h3 style="margin: 0 0 4px;">${feature}</h3>
            ${
               dataPoints !== undefined
                  ? `<div style="margin-bottom: 4px;"><strong>${dataPoints} ${t(
                       "model.tooltip_tree.instances",
                    )}</strong></div>`
                  : ""
            }
            ${
               percentage !== undefined
                  ? `<div><strong>${percentage}%  ${t("model.tooltip_tree.of_data")}</strong></div>`
                  : ""
            }
         </div>
         `,
         )
         .style("left", `${event.pageX + 15}px`)
         .style("top", `${event.pageY + 15}px`)
         .style("background", "rgba(0, 0, 0, 0.75)")
         .style("border-radius", "8px")
         .style("box-shadow", "0px 4px 12px rgba(0, 0, 0, 0.2)")
         .style("pointer-events", "none")
         .style("white-space", "nowrap");

      // Atualiza o caminho atual
      let currentNodePath = [];
      let ancestors = node.ancestors();
      ancestors.forEach((ancestor) => {
         currentNodePath.unshift({
            Feature: ancestor.data.Feature !== undefined ? ancestor.data.Feature : "Predict",
            Threshold: ancestor.data.Threshold !== undefined ? ancestor.data.Threshold : ancestor.data.Predict,
            Operation: ancestor.data.Operation,
            Side: ancestor.data.Side,
         });
      });
      setCaminhoAtual(currentNodePath);

      d3.select(event.target).attr("fill", "#333").attr("stroke", "#aaa").attr("stroke-width", 3);

      currentNode.current = event.target;
   }

   function handleMouseOut(event, node) {
      const tooltip = d3.select(".d3-tooltip");

      // Esconde o tooltip
      tooltip.style("opacity", 0);

      d3.select(event.target)
         .attr("fill", (node) => DecisionTreeKPI(node))
         .attr("stroke", "transparent")
         .attr("stroke-width", 0);
   }

   function handleEvent(event) {
      let target = event.target;
      let ancestors = target.__data__.ancestors();

      switch (event.type) {
         case "click":
            handleClick(target, ancestors);
            break;
         case "mouseover":
            handleMouseOver(event, ancestors);
            break;
         default:
      }
   }

   function handleClick(target, ancestors) {
      let targetNode = target.__data__;
      let rootNode = ancestors[parseInt(ancestors.length) - 1];
      if (targetNode === rootNode || currentData !== result) setCurrentData(result);
      else {
         let selectedNodePath = recursiveNodeConvert(rootNode, ancestors);
         setCurrentData(selectedNodePath);
      }
   }

   function recursiveNodeConvert(node, ancestors) {
      if (node.children == null || node.children === undefined) return { Predict: node.data.Predict };
      return {
         children: node.children
            .filter((childNode) => ancestors.includes(childNode.parent))
            .map((childNode) => recursiveNodeConvert(childNode, ancestors)),
         Operation: node.data.Operation,
         Feature: node.data.Feature,
         Threshold: node.data.Threshold,
         Side: node.data.Side,
      };
   }

   return <svg ref={d3Chart} id="decisiond3-svg" />;
};
