import React, { useCallback, useEffect, useState } from "react";
import dp from "../helpers/data_parse";
import "../assets/css/components/tasks.css";
import taskService from "../services/TaskService";
import { useDispatch, useSelector } from "react-redux";
import { selectOpenModalTask, setOpenModalTask, setRunningTask, setTaskList } from "../store/slices/tasks/tasksSlice";
import HistoryLoadingIcon from "../assets/icons/jsxIcons/HistoryLoadingIcon";
import BouncingDotsLoader from "./BouncingDotsLoader";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BsListTask } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import "./../translation/i18n";
import handleErrorResponse from "../helpers/handleErrorResponse";
import { setNeedUpdateProjectList } from "../store/slices/projects/projectsSlice";
import { setNeedUpdateModelResult, setNeedUpdateMyDatasources } from "../store/slices/cache/cacheSlice";
import { toast } from "react-toastify";
import { setUploading } from "../store/slices/uploadingFlag/uploadingSlice";
import { DataGrid, ptBR, esES, frFR } from "@material-ui/data-grid";

import { Modal } from "semantic-ui-react";
import { styled } from "@material-ui/core";

const CustomNoRowsOverlay = styled("div")(({ theme }) => ({
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   height: "100%",
   width: "100%",
   position: "absolute",
   top: 0,
   left: 0,
   fontSize: 18,
   color: theme.palette.text.secondary,
   backgroundColor: "transparent",
   zIndex: 1,
}));

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
   "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
   },
}));

const Tasks = () => {
   const dispatch = useDispatch();
   const history = useHistory();
   const isProjectPage = useLocation().pathname.includes("project");
   const { t, i18n } = useTranslation();
   const language = i18n.language;

   const openModalTask = useSelector(selectOpenModalTask);

   const [taskListContent, setTaskListContent] = useState([]);

   const taskList = useSelector((state) => state.tasks.taskList);
   const runningTask = useSelector((state) => state.tasks.runningTask);
   const [tasksRunning, setTasksRunning] = useState([]);

   const handleTasks = useCallback(() => {
      taskList.forEach((task) => {
         if (task.type === "TASK_NOT_FOUND") return;

         if (task.datasetName === null) return;

         let taskAlreadyExists = tasksRunning.find((t) => t.task === task.task);

         if (taskAlreadyExists) return;

         setTasksRunning([
            ...tasksRunning,
            {
               type: task.type,
               task: task.task,
               datasetName: task.datasetName,
               modelName: task.modelName,
            },
         ]);
      });
   }, [taskList, tasksRunning]);

   const checkTasksFinished = useCallback(() => {
      tasksRunning.map((task) => {
         let taskExists = taskList.find((t) => t.task === task.task);
         if (!taskExists) {
            setTasksRunning(tasksRunning.filter((t) => t.task !== task.task));
            return taskService.gettaskcompleted(task.task).then((data) => {
               if (data?.error) {
                  handleErrorResponse(history, data, t);
               } else {
                  switch (task.type) {
                     case "MODEL":
                        dispatch(setNeedUpdateProjectList(true));
                        toast.success(t("notificationquickstart.notification.model") + ": " + task.modelName);
                        break;
                     case "UPLOAD_DATASET":
                        !isProjectPage && toast.success(t("project.upload") + " " + task.datasetName);
                        dispatch(setNeedUpdateMyDatasources(true));
                        dispatch(setNeedUpdateProjectList(true));
                        break;
                     case "COPY_DATASET":
                        toast.success(t("popup.action.copydataproxy.success") + task.datasetName);
                        dispatch(setNeedUpdateProjectList(true));
                        dispatch(setNeedUpdateMyDatasources(true));
                        break;
                     case "DERIVATE_DATASET":
                        isProjectPage && dispatch(setNeedUpdateProjectList(true));
                        toast.success(t("project.upload") + " " + task.datasetName);
                        break;
                     case "BATCH_PREDICTION":
                        toast.success(t("popup.predictions.success.makebatchprediction"));
                        dispatch(setNeedUpdateModelResult(true));
                     default:
                        break;
                  }
                  dispatch(setUploading(false));
               }
            });
         } else return null;
      });
   }, [dispatch, history, t, taskList, tasksRunning]);

   const fetchRunningTasks = useCallback(() => {
      taskService.allrunningusertask().then((data) => {
         dispatch(setTaskList(data));
         if (data.length === 0) {
            dispatch(setRunningTask(false));
         }
      });
   }, [dispatch]);

   useEffect(() => {
      if (runningTask || taskList.length > 0 || tasksRunning.length > 0) {
         handleTasks();
         checkTasksFinished();
         if (taskList.length === 0) {
            fetchRunningTasks();
         } else {
            const intervalId = setInterval(fetchRunningTasks, 2000);
            return () => {
               clearInterval(intervalId);
            };
         }
      }
   }, [fetchRunningTasks, taskList, runningTask, handleTasks, checkTasksFinished, tasksRunning]);

   useEffect(() => {
      if (taskList.length > 0) {
         const updateTasks = () => {
            setTaskListContent(
               taskList
                  .filter((task) => task.type !== null && task.type !== "TASK_NOT_FOUND")
                  .map((task) => ({
                     id: task.task,
                     name: task.modelName || task.datasetName || "--",
                     task: t(`components.tasks.${task.type}`) || "--",
                     started: dp.parseDiffTime(task.date),
                     status: task.progress ?? "--",
                  })),
            );
         };

         updateTasks();

         const intervalId = setInterval(updateTasks, 60000);

         return () => {
            clearInterval(intervalId);
         };
      } else {
         setTaskListContent([]);
      }
   }, [t, taskList]);

   const toggleTaskPopUp = () => {
      dispatch(setOpenModalTask(!openModalTask));
   };

   const link = "components.tasks";

   let localeText;

   switch (language) {
      case "pt_br":
      case "pt_pt":
         localeText = ptBR.props.MuiDataGrid.localeText;
         break;
      case "es_es":
         localeText = esES.props.MuiDataGrid.localeText;
         break;
      case "fr_fr":
         localeText = frFR.props.MuiDataGrid.localeText;
         break;

      default:
         break;
   }

   const tasksLength = taskList.filter((task) => task.type !== null && task.type !== "TASK_NOT_FOUND").length;

   return (
      <div className="tasks-container">
         <button className="tasks" onClick={toggleTaskPopUp}>
            <p>{t(`${link}.title`) + ": " + tasksLength}</p>
            {tasksLength > 0 ? <BouncingDotsLoader /> : <BsListTask size={30} fill="#282828" />}
         </button>

         <Modal open={openModalTask} onClose={toggleTaskPopUp} size="small" style={{ padding: "1em" }} closeIcon>
            <CustomDataGrid
               localeText={localeText}
               rows={taskListContent}
               columns={[
                  { field: "name", headerName: t(`${link}.name`), width: 200 },
                  { field: "task", headerName: t(`${link}.task`), width: 200 },
                  { field: "started", headerName: t(`${link}.started`), width: 120 },
                  {
                     field: "status",
                     headerName: t(`${link}.status`),
                     width: 200,
                     renderCell: (params) => (
                        <div className="status-td">
                           <HistoryLoadingIcon style={{ size: 40, color: "#7f7f7f" }} />
                           <span>{params?.value + "%"}</span>
                        </div>
                     ),
                  },
               ]}
               autoHeight
               pageSize={5}
               disableSelectionOnClick
               components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
               }}
               componentsProps={{
                  noRowsOverlay: { children: t(`${link}.default`) },
               }}
            />
         </Modal>
      </div>
   );
};

export default Tasks;
